.tooltip {
    position: relative;
    display: inline-block;
  }
  
.tooltip .tooltiptext {
  visibility: hidden;
  width: 264px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 20;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 23%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  display: block;
}


.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 20;
  top: 120%;
  left: 50%;
  margin-left: -140px;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  display: block;
}

.finishIcon{
  color: gray;
}

.finishIcon2{
  color: black;
}
.styledInput{
  border: 1px solid rgb(210, 211, 212);
  height: 40px;
  margin-right:'5px';
  border-radius: 6px;
  width: 100%;
  padding: 5px;
}

.styledInputError{
  border: 1px solid rgb(255,0,0);
  height: 40px;
  margin-right: '5px';
  border-radius: 6px;
  width: 100%;
  padding: 5px;
}