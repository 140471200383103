@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900);

.loader {
  width: 200px;
  height: 160px;
  margin: 0 auto;
  overflow: hidden;
}
.loader .image {
  width: 200px;
  height: 160px;
  font-size: 40px;
  text-align: center;
  transform-origin: bottom center;
  animation: 3s rotate infinite;
}
.loader span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

@keyframes rotate{
  0% {
    transform: rotate(90deg);
  }
  10% {
    opacity: 0;
  }
  35% {
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    transform: rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: rotate(-90deg);
  }
}